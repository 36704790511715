import React, { createContext, useContext, useState } from "react"
import { arrayOf, element } from "prop-types"

const FootnoteContext = createContext({})

export const useFootnote = () => {
  return useContext(FootnoteContext)
}

const useProvideFootnote = () => {
  const [footnotes, setFootnotes] = useState({})

  const setNamespacedFootnote = ({ name, value }) => {
    const newObj = { ...footnotes }
    newObj[name] = value
    setFootnotes(newObj)
  }

  return [footnotes, setNamespacedFootnote]
}

const FootnoteProvider = ({ children }) => {
  const footnoteTuple = useProvideFootnote()
  return (
    <FootnoteContext.Provider value={footnoteTuple}>
      {children}
    </FootnoteContext.Provider>
  )
}

FootnoteProvider.propTypes = {
  children: arrayOf(element),
}

export default FootnoteProvider
