import { arrayOf, element, string, oneOfType } from "prop-types"
import React from "react"
import { useFootnote } from "../../providers/footnoteProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

const Footnote = ({ children, footnoteId }) => {
  const [footnotes] = useFootnote()

  return (
    <aside
      className={`${
        !!footnotes[footnoteId] ? "h-auto my-4  pb-2 pt-4" : "h-0 my-0 p-0"
      } px-8 overflow-hidden transition-all bg-green-300 dark:bg-green-900 rounded-md relative`}
    >
      <FontAwesomeIcon icon={faInfoCircle} className="absolute top-1 right-1" />
      {children}
    </aside>
  )
}

Footnote.propTypes = {
  children: element,
  footnoteId: string,
}

export default Footnote
