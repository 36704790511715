import React, { createContext, useContext } from "react"
import { arrayOf, element } from "prop-types"

const BlogImageContext = createContext()

export const useBlogImage = () => useContext(BlogImageContext)

const BlogImageProvider = ({ children, images }) => {
  return (
    <BlogImageContext.Provider value={images}>
      {children}
    </BlogImageContext.Provider>
  )
}

BlogImageProvider.propTypes = {
  children: arrayOf(element),
}

export default BlogImageProvider
