import React from "react"
import { string, element } from "prop-types"
import { useFootnote } from "../../providers/footnoteProvider"

const FootnoteTriggerText = ({ children, footnoteId }) => {
  const [footnotes, setFootnotes] = useFootnote()

  const trigger = () => {
    setFootnotes({ name: footnoteId, value: !footnotes[footnoteId] })
  }
  return (
    <div className="inline-block relative cursor-pointer" onClick={trigger}>
      <div className="underline decoration-dotted mr-3">
        {children}
        <div className="align-super absolute top-0 right-0 text-sm rounded-full">
          ?
        </div>
      </div>
    </div>
  )
}

FootnoteTriggerText.propTypes = {
  children: element,
  footnoteId: string,
}

export default FootnoteTriggerText
