/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { MDXProvider } from "@mdx-js/react"
import "./src/styles/global.css"
import * as AllBlogComponents from "@components/blog"

const wrapRootElement = ({ element }) => (
  <MDXProvider components={AllBlogComponents}>{element}</MDXProvider>
)

export { wrapRootElement }
