import React from "react"
import { bool, string } from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { useBlogImage } from "../../providers/blogImageProvider"

const ImageWithAttribution = ({ imageName, left, right, center }) => {
  const images = useBlogImage()
  if (!imageName) return null
  const objI = images[imageName]
  const image = objI.gImage
  const attributionName = objI.imageMeta.attributionName
  const attributionLink = objI.imageMeta.attributionLink
  const alt = objI.imageMeta.alt
  return (
    <figure
      className={`${left ? "float-left " : ""}${right ? "float-right " : ""}${
        center ? "mx-auto " : ""
      }${left || right || center ? "w-full md:w-2/4" : ""}`}
    >
      <GatsbyImage image={image} alt={alt} />
      {attributionName && (
        <figcaption className="text-sm mt-0">
          Picture by{" "}
          {attributionLink ? (
            <a href={attributionLink} target="_blank" rel="noreferrer">
              {attributionName}
            </a>
          ) : (
            <b>{attributionName}</b>
          )}
        </figcaption>
      )}
    </figure>
  )
}

ImageWithAttribution.propTypes = {
  imageName: string.isRequired,
  left: bool,
  right: bool,
}
export default ImageWithAttribution
