import React, { useState } from "react"

const emailTest = new RegExp("[a-zA-Z0-9_\\-\\.]+@[a-zA-Z0-9\\-]+\\.\\w+")

export default function EmailListSignup() {
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")

  const [valid, setValid] = useState(false)
  return (
    <div className="w-full mx-auto" id="email-signup" key="email-signup">
      <div
        id="mc_embed_signup"
        className="rounded-md dark:bg-gray-800/60 bg-gray-50 not-prose card"
      >
        <form
          action="https://gmail.us14.list-manage.com/subscribe/post?u=703d9160efc2d599e965df310&amp;id=58a1247f13"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <h2 className="text-2xl md:text-4xl">Subscribe</h2>
            <div className="indicates-required ml-auto text-right">
              <span className="asterisk">*</span> indicates required
            </div>
            <div className="mc-field-group text-md md:text-lg grid gap-3">
              <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                value={email}
                name="EMAIL"
                className="required email p-2 indent-1 text-black rounded-md text-sm dark:bg-gray-700 bg-gray-300 dark:text-gray-200"
                id="mce-EMAIL"
                onBlur={e => {
                  setValid(
                    e.currentTarget.validity.valid &&
                      emailTest.test(e.currentTarget.value)
                  )
                }}
                onChange={e => {
                  setEmail(e.currentTarget.value)
                }}
              />
            </div>
            <div className="mc-field-group text-md md:text-lg grid ">
              <label htmlFor="mce-FNAME">First Name </label>
              <input
                type="text"
                value={firstName}
                name="FNAME"
                className="p-2 indent-1 text-black rounded-md text-sm dark:bg-gray-700 bg-gray-300 dark:text-gray-200"
                id="mce-FNAME"
                onChange={e => {
                  setFirstName(e.currentTarget.value)
                }}
              />
            </div>
            <div id="mce-responses" className="clear foot">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              style={{ position: "absolute", left: -5000 }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_703d9160efc2d599e965df310_58a1247f13"
                tabIndex={-1}
                value=""
                readOnly
              />
            </div>
            <div className="optionalParent">
              <div className="clear foot">
                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  disabled={!valid}
                  className="disabled:hover:scale-100 disabled:hover:text-inherit disabled:hover:text-black disabled:text-black  disabled:hover:bg-gray-300 disabled:bg-gray-300 disabled:cursor-default text-gray-200 bg-green-600 px-6 py-2 rounded-md text-lg md:text-xl font-bold tracking-tight mt-8 cursor-pointer hover:scale-105 transition-all hover:bg-green-400 hover:text-black"
                  onClick={() => {
                    window.plausible("Signup")
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
